<template>
  <div>
    <div id="print" class="mx-auto col-11 mt-5">
      <div class="row col-12 flex flexLeft">
        <p class="h4 text-center fw-3">{{year}}年度 {{name}} - 申請</p>
      </div>
      <hr class="title">
    </div>
    <div id="appForm" class="mx-auto col-11 mt-5">
      <div class="flex flexLeft mx-auto contentsWidth">
        <p class="h4 text-center fw-3">{{year}}年度 {{name}} - 申請</p>
      </div>
      <hr class="title contentsWidth">

      <div class="notice mx-auto contentsWidth my-4 py-3 px-4">
        <p class="mb-1 warning">・すべての必要項目に入力後、登録を行ってください。</p>
        <p class="mb-1 warning">・数字は半角で入力してください。</p>
        <p class="mb-1">・添付ファイルについては、一時保存または申請後に閲覧できます。</p>
        <p
          v-if="Object.keys(assets).length > 0"
          class="mb-1">・添付ファイルの原本は以下からダウンロードしてください。</p>
        <p
          v-for="(asset, index) in assets" :key="`openFile_${index}`"
          class="mb-0 ml-5"><b-link
                @click="downloadOpenFile(asset.id, asset.name)">
                  {{asset.name}}
                </b-link></p>
      </div>

      <div
        class="mb-3 mx-auto contentsWidth">
        <div
          v-if="viewControl">
          <v-app-controlview
            :code="code"
            :status="status"
            :comments="comments"
            :isShowComments="true"/>
        </div>

        <div
          v-for="(page, index) in pages" :key="index">
          <v-app-form
            :writable="canSend"
            :pageIndex="index"
            :assets="assets"
            :downloadFormFile="downloadFormFile"
            :downloadOpenFile="downloadOpenFile"
            :downloadApploadedFile="downloadApploadedFile"
            />
        </div>
      </div>

      <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
        <p
          v-show="hasEmpty"
          class="warning">
          未入力の必須項目があります。すべての必須項目に入力後、申請を行ってください。
        </p>
        <p class="mb-1">添付ファイルについては、一時保存または申請後に閲覧できます。</p>
      </div>

      <div class="mt-4 mb-3 flex flexCenter contentsWidth">
        <b-button class="btn btn-lg mr-4 bold" to="/top">トップに戻る</b-button>
        <!-- 申請期間内かつ未提出・一時保存の場合のみ表示 -->
        <b-button
          v-if="onTime && isUnsubmited"
          @click="tempSave"
          variant="primary"
          class="btn btn-lg mr-4 bold"
          >一時保存</b-button>
        <b-button
          v-if="canSend"
          @click="save"
          variant="primary"
          class="bold mr-4"
          >申請</b-button>
        <b-button
          v-if="onTime && isSubmited"
          @click="withdraw"
          variant="dark"
          class="btn btn-primary btn-lg bold"
          >申請の削除</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import download from '@/modules/download';
import AppForm from '@/components/gas/parts/Form.vue';
import AppControlview from '@/components/gas/parts/Controlview.vue';
import CONST_STATUS from '@/constants/appStatus';


export default {
  name: 'gasApplicationForm',
  components: {
    'v-app-form': AppForm,
    'v-app-controlview': AppControlview,
  },
  data() {
    return {
      isEdit: false,
      apptypeId: null,
      year: null,
      name: '',
      code: null,
      comments: {},
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      initTabChange: {},
      onTime: null,
      viewLocateMsg: true,
      formStatus: null,
      assets: {},
      close_datetime: null,
      pageErrs: [],
    };
  },
  computed: {
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    hasEmpty() {
      // 入力フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      const formOptionData = this.$store.state.gasApplicationForm.optionDataList;
      const findFormResult = inputs.find((input) => {
        if (formOptionData[input.option_id] && !formOptionData[input.option_id].is_required) {
          return false;
        }
        const noSelect = input.field_answer_id === null;
        const notext = input.field_answer_text === '';
        const noFile = input.uploadedFileKey === '';
        return noSelect && notext && noFile;
      });
      // return typeof findUserResult !== 'undefined' || typeof findFormResult !== 'undefined';
      return typeof findFormResult !== 'undefined';
    },
    isUnsubmited() {
      return this.status === null || this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
    },
    isSubmited() {
      return this.status === CONST_STATUS.APP_STATUS.SUBMIT;
    },
    viewControl() {
      const viewControlStatusList = CONST_STATUS.GAS_VIEW_CONTROL_STATUS;
      return viewControlStatusList.indexOf(this.status) !== -1;
    },
    formStatuses() {
      return CONST_STATUS.FROM_STATUS;
    },
    canSend() {
      const isInit = this.formStatus === this.formStatuses.INIT;
      const isEdit = this.formStatus === this.formStatuses.EDIT;
      return isInit || isEdit;
    },
    hasChange() {
      return this.$store.state.gasApplicationForm.hasChange;
    },
    upFileKeys() {
      return this.$store.state.gasApplicationInput.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.gasApplicationInput.tempUpFileList;
    },
  },
  methods: {
    async fetchApptypeData(apptypeId) {
      const param = {
        apptypeId,
      };
      const promiseFuncs = [
        api.send('/api/application/base', param),
        api.send('/api/application/myapp', param),
        api.send('/api/application/form', param),
        api.send('/api/application/edit', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.error(err);
        });
      if (!responses) {
        await this.alert('申請を取得できませんでした。', false);
        this.$store.dispatch('page/offLoading');
        this.$router.push({ name: 'gasTop' });
        return;
      }
      const { base, onTime } = responses[0].data;
      this.year = base.year;
      this.name = base.name;
      this.onTime = onTime;
      this.assets = base.assets;
      this.close_datetime = base.close_datetime;
      const { myapp, formStatus } = responses[1].data;
      this.formStatus = formStatus;
      if (myapp !== null) {
        this.code = myapp.code;
        this.comments = myapp.comments;
        this.status = myapp.status;
        // 一時保存の時はデータがあっても編集ではない
        this.isEdit = this.status !== CONST_STATUS.APP_STATUS.TEMPSAVE;
      }
      const { pages } = responses[2].data;
      const { userInfo, answers, applicationId } = responses[3].data;
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setApplicationId', applicationId);
      this.$store.commit('gasApplicationForm/setYear', base.year);
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      // 初回は、ユースケースに応じてユーザー情報のデータを初期配置する
      if (myapp === null) {
        this.$store.commit('gasApplicationInput/setUserInfo', { pages, userInfo });
      }
    },
    async tempSave() {
      this.pageErrs = [];
      if (!await this.confirm('現在の内容で一時保存をしてもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      // eslint-disable-next-line
      const storeData = {
        apptypeId: this.apptypeId,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/application/tempSave', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      this.$store.commit('gasApplicationInput/initErrMessages');
      this.$store.commit('gasApplicationForm/setHasChange', false);
      this.hasUserErr = false;
      this.hasPageErr = [];
      await this.alert('一時保存しました。', false);
      this.$store.dispatch('page/offWaiting');
      window.location.reload();
    },
    async save() {
      if (!await this.confirm('現在の内容で申請をしてもよろしいですか？')) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        apptypeId: this.apptypeId,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/application/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.pageErrs = err.response.data.pageErrs;
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('gasApplicationInput/setCode', response.data.code);
      // リダイレクト
      this.$router.push({ name: 'gasApplicationEnd', meta: { locate: false } });
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        apptypeId: this.apptypeId,
        assetId,
        assetName,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/application/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$store.state.gasApplicationForm.application_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: this.$store.state.gasApplicationInput.answer_no, // ファイルは当面１つ固定
      };
      const url = '/api/application/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      this.$store.commit('gasApplicationInput/setAnsNum', 1);
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async withdraw() {
      if (!await this.confirm('入力内容はすべて破棄されます。この申請を削除してもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptypeId: this.apptypeId,
      };
      const response = await api.send('/api/application/withdraw', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('申請を削除しました。');
      // トップへリダイレクト
      this.viewLocateMsg = false;
      this.$router.push({ name: 'gasTop' });
    },
    async downloadApploadedFile(uploadedFileKey, index, fileName) {
      const fileKey = this.upFileKeys[uploadedFileKey][index];
      const url = '/api/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[uploadedFileKey] || !this.tempUpFileList[uploadedFileKey][fileKey]) {
        const itemNumber = `${uploadedFileKey}-${fileKey}`;
        const tempUpUrl = '/api/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        const { files } = this.$store.state.gasApplicationInput;
        tempParam.file = files[uploadedFileKey];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: uploadedFileKey,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('gasApplicationInput/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[uploadedFileKey][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.hasChange || to.name === 'gasApplicationEnd' || this.viewLocateMsg === false) {
      next();
    } else if (await this.confirm('入力内容は破棄されます。このページから移動してもよろしいですか？')) {
      next();
    } else {
      next(false);
    }
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('gasApplicationForm/setIsCms', false);
    const { apptypeId } = this.$route.params;
    this.apptypeId = apptypeId;
    this.$store.commit('gasApplicationForm/setApplicationTypeId', apptypeId);
    await this.fetchApptypeData(apptypeId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
  }

  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  #print {
    display: none;
  }
  #appForm {
    display: block;
  }
  @media print{
    #print{
      overflow-y: visible;
      display: block;
      white-space:pre-wrap;
      word-wrap: break-word;
    }
    #appForm{
      display: none;
    }
  }
</style>
